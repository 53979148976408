@import "solar/Tokens.scss";

.component {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $sol-z-modal-dialog;
  width: 100%;
  background-color: $sol-color-off-white;
  color: $sol-color-black;
  transition: transform 250ms ease-in-out;

  @media (min-width: $sol-breakpoint-lg) {
    width: 50%;
  }
}

.visible {
  transform: translateX(0);
}

.hidden {
  transform: translateX(100%);
}

.scroll {
  position: relative;
  overflow-y: auto;
}

.close {
  margin-top: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-md;
  }
}

.content {
  padding-top: $sol-space-lg;
  padding-bottom: $sol-space-lg;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-xl;
    padding-bottom: $sol-space-xl;
  }
}
