@import "solar/Tokens.scss";

.component {
  font-size: 15px;
  line-height: 160%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 17px;
  }

  strong {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  a {
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.center {
  text-align: center;
}

.mono {
  font-weight: 500;
  font-size: 12px;
  font-family: $sol-font-family-mono;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 14px;
  }
}
