@import "solar/Tokens.scss";

.component {
  font-family: $sol-font-family-serif;
}

.h1 {
  font-size: 68px;
  line-height: 90%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 123px;
    line-height: 90%;
  }
}

.h2 {
  font-size: 42px;
  line-height: 90%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 76px;
  }
}

.h3 {
  font-size: 26px;
  line-height: 90%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 47px;
  }
}

.h4 {
  font-size: 26px;
  line-height: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 29px;
  }
}
