@import "solar/Tokens.scss";

$height-component: 66px;

.component {
  position: relative;
  display: grid;
  align-content: center;
  width: 100%;
  height: $height-component;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;
}

.primary,
.secondary,
.tertiary {
  display: grid; // rt: added to fix child image links
  align-items: center;
  justify-content: center;
}

.primary {
  justify-content: start;
}

.tertiary {
  justify-content: end;
}

.actions {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: $sol-space-xs;
}

.search {
  margin-right: $sol-space-sm;
}
