@import "solar/Tokens.scss";

.component {
  :global {
    .PhoneInputInput {
      border: none;
      font-weight: 500;
      font-family: $sol-font-family;

      &::placeholder {
        font-weight: 500;
        font-family: $sol-font-family;
      }
    }

    .PhoneInput--focus {
      .PhoneInputCountryIcon {
        border: none;
        box-shadow: none;
      }

      .PhoneInputCountrySelectArrow {
        border-color: var(--PhoneInputCountrySelectArrow-color);
        color: var(--PhoneInputCountrySelectArrow-color);
      }
    }

    .PhoneInputCountrySelect {
      &:active,
      &:focus {
        opacity: 0;

        .PhoneInputCountryIcon--border {
          border: none;
          box-shadow: none;
        }
      }
    }

    .PhoneInputCountryIcon--border {
      border: none;
      box-shadow: none;
    }
  }

  &.no-bold {
    :global {
      .PhoneInputInput {
        font-weight: 300;

        &::placeholder {
          font-weight: 300;
          color: $sol-color-black;
        }
      }
    }
  }

  &.small {
    .input {
      padding: $sol-space-xs;
      border: 2px solid $sol-color-black;
      margin-top: $sol-space-xs;

      &.invalid {
        border-color: $sol-color-orange;
      }
    }
  }
}

.input {
  margin-top: $sol-space-sm;
  padding: 24px 32px; // rt: style this without pxs
  border: 2px solid $sol-color-gray; // rt: style this without pxs
  border-radius: $sol-border-radius-sm;
}

.invalid {
  :global {
    .PhoneInputInput {
      color: $sol-color-orange;
    }
  }
}
