@import "solar/Tokens.scss";
$lqip_blur: 10px;

.component {
  position: relative;
  overflow: hidden;
  border-radius: $sol-space-xs;
  text-align: center;
  isolation: isolate;

  @media (min-width: $sol-breakpoint-lg) {
    border-radius: $sol-space-sm;
  }
}

.visual {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* prevent flickering when used with swiper */
  -webkit-transform: translate3d(0, 0, 0);
}

.poster {
  z-index: 1;
  opacity: 1;
  transition: opacity 200ms ease;

  &.hidden {
    opacity: 0;
  }
}

.lqip {
  top: -$lqip_blur;
  left: -$lqip_blur;
  z-index: 2;
  width: calc(100% + $lqip_blur * 2);
  height: calc(100% + $lqip_blur * 2);
  filter: blur($lqip_blur);
}

.info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  /* prevent flickering when used with swiper */
  -webkit-transform: translate3d(0, 0, 0);
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba($sol-color-black, 0.2);
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 7 / 10;
}

.link,
.link:hover,
.link:focus,
.link:active {
  color: $sol-color-white;
}

.top {
  position: absolute;
  top: $sol-space-lg;
  right: 0;
  left: 0;
}

.bottom {
  position: absolute;
  right: 0;
  bottom: $sol-space-lg;
  left: 0;
}

.top,
.bottom {
  padding-right: $sol-space-xs;
  padding-left: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}

.country {
  font-weight: 500;
  font-size: 12px;
  font-family: $sol-font-family-mono;
  text-transform: uppercase;
}

.name {
  margin-top: $sol-space-md;
  word-spacing: 100vw;
}

.cta {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba($sol-color-white, 0.4);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
