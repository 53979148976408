@import "solar/Tokens.scss";

$actions-hidden-offset: -150px;

.navbar {
  z-index: $sol-z-navbar;
  color: $sol-color-white;
  text-align: center;
}

.positionAbsolute {
  position: absolute;
  right: 0;
  left: 0;
}

.darkText {
  color: $sol-color-black;
}

.actions {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $sol-z-navbar + 1;
  display: inline-grid;
  grid-template-columns: auto;
  align-items: center;
  justify-self: center;
  transition: transform 250ms ease-in-out;
}

.actionsVisible {
  transform: translate(-50%, $sol-space-xs);
}

.actionsHidden {
  transform: translate(-50%, $actions-hidden-offset);
}

.button {
  display: inline-flex;
  justify-content: center;
  padding: $sol-space-sm $sol-space-lg;
  border-radius: $sol-border-radius-half;
  background-color: $sol-color-orange;
  color: $sol-color-off-white;
  font-weight: 500;
  font-size: 16px;
  font-family: $sol-font-family;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  filter: $sol-shadow-dropdown;
  cursor: pointer;
  user-select: none;
  place-items: center;

  @media (min-width: $sol-breakpoint-lg) {
    padding: ($sol-space-md * 0.9) $sol-space-xl;
    font-size: 18px;
  }
}

.noEvents {
  pointer-events: none;
}
