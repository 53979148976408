@import "solar/Tokens.scss";

.component {
  font-family: $sol-font-family-serif;
}

.sm {
  font-size: 21px;
  line-height: 1.2em;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 29px;
  }
}

.md {
  font-size: 26px;
  line-height: 1.2em;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 38px;
  }
}

.lg {
  font-size: 42px;
  line-height: 110%;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: 76px;
    line-height: 100%;
  }
}

.center {
  text-align: center;
}

.sans {
  font-family: $sol-font-family;
}
