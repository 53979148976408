.clamp {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.markdown {
  .more {
    display: none;
  }
}

.markdown:not(.expanded) {
  > * {
    display: none;
  }

  .more {
    display: inline-block;
  }

  p:first-of-type {
    display: inline;

    &::after {
      content: ".. ";
    }
  } // p:first-of-type
} // .markdown

.more {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.short-markdown {
  p:last-of-type {
    display: inline;
  }
}
