@import "solar/Tokens.scss";

.breakLg {
  display: none;

  @media (min-width: $sol-breakpoint-lg) {
    display: inherit;
  }
}

.spacelg {
  display: inline;

  @media (min-width: $sol-breakpoint-lg) {
    display: none;
  }
}

.breakSm {
  display: inherit;

  @media (min-width: $sol-breakpoint-lg) {
    display: none;
  }
}

.spaceSm {
  display: none;

  @media (min-width: $sol-breakpoint-lg) {
    display: inline;
  }
}
