@import "solar/Tokens.scss";

.component {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: $sol-space-md;
  margin-bottom: $sol-space-md;
  background: $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-lg;
    margin-bottom: $sol-space-lg;
  }
}

.light {
  background-color: $sol-color-off-white;
  opacity: 0.1;
}
