@import "solar/Tokens.scss";

.component {
  width: 100%;
  padding: $sol-space-xs $sol-space-md;
  background: $sol-color-neutral-1;
  color: $sol-color-black;
  font-size: 13px;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    padding: 13px $sol-space-md;
    font-size: 15px;
  }
}

.number {
  color: $sol-color-black;
  font-weight: 500;

  &:hover {
    color: $sol-color-black;
  }
}
