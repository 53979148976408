@import "solar/Tokens.scss";

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.photo {
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  margin-bottom: $sol-space-sm;
}

.title {
  margin-bottom: $sol-space-xs;
}

.button {
  margin-top: $sol-space-md;
}
