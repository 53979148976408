@import "solar/Tokens.scss";

.component {
  width: 100%;
  height: auto;
}

.rounded {
  border-radius: $sol-space-xs;

  @media (min-width: $sol-breakpoint-lg) {
    border-radius: $sol-space-sm;
  }
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}
