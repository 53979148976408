@import "solar/Tokens.scss";

.component {
  position: relative;
  width: 100%;
  max-width: $sol-breakpoint-2xl;
  margin: 0 auto;
}

.paddingY {
  padding-top: $sol-space-xl;
  padding-bottom: $sol-space-xl;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-3xl;
    padding-bottom: $sol-space-3xl;
  }
}

.paddingX {
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-lg;
    padding-left: $sol-space-lg;
  }
}
