@import "solar/Tokens.scss";

.component {
  padding-top: 70px;
  padding-bottom: $sol-space-sm;
  color: $sol-color-black;
  font-family: $sol-font-family-mono;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: 75px;
    padding-bottom: $sol-space-lg;
  }
}

.links {
  display: grid;
  row-gap: 15px;
  justify-content: center;
  font-size: 15px;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    grid-auto-flow: column;
    justify-content: space-between;
    font-size: 17px;
  }
}

.link,
.faux {
  text-decoration: none;

  button {
    font-weight: 300;
    text-decoration: none;
  }
}

.logo {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 160px;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-3xl * 2;
  }
}

.orange {
  background-color: $sol-color-orange;
}

.yellow {
  background-color: $sol-color-yellow;
}
