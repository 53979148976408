@import "solar/Tokens.scss";

.component {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: $sol-space-sm;
  padding-right: $sol-space-sm;
  padding-bottom: $sol-space-sm;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-lg;
    padding-left: $sol-space-lg;
  }
}

.narrow {
  max-width: $sol-breakpoint-2xl;
}

.grid {
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  align-items: center;
}

.primary {
  justify-self: left;
}

.secondary {
  display: grid;
  align-items: center;
  justify-content: center;
}

.tertiary {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;
  column-gap: $sol-space-sm;
}
