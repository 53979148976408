@import "solar/Tokens.scss";

$max-height: 250px;
$padding-x: $sol-space-xs + 7px;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $sol-space-2xs;
  margin-bottom: $sol-space-2xs;
}

.toggle {
  display: flex;
  justify-content: space-between;
  padding: $sol-space-sm $padding-x;
  border: solid 1px $sol-color-gray;
  border-radius: $sol-border-radius-sm;
  background: $sol-color-white;
  cursor: pointer;
}

.text {
  display: flex;
  align-items: center;
}

.arrow {
  display: flex;
  justify-content: center;
  width: 30px;
  padding: 0 $sol-space-2xs;
}

.open {
  transform: rotate(90deg);
}

.closed {
  transform: rotate(-90deg);
}

.list {
  position: relative;
}

.menu {
  position: absolute;
  z-index: $sol-z-dropdown-menu;
  overflow: scroll;
  width: 100%;
  max-height: $max-height;
  padding: 0;
  border: solid 1px $sol-color-gray;
  border-radius: $sol-border-radius-sm;
  background: $sol-color-white;
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 20%);
}

.hidden {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $sol-space-xs;
  cursor: pointer;
}

.highlighted {
  background: $sol-color-gray-light;
}

.selected {
  font-weight: 500;
}

.checkmark {
  width: 26px;
  padding: 0 $sol-space-2xs;
}
