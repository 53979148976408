@import "solar/Tokens.scss";
$menu-width: 10em;

.component {
  display: none;
  font-weight: 500;

  @media (min-width: $sol-breakpoint-lg) {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.wrapper {
  position: relative;
  display: block;
}

.links {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  height: 100%;
  margin: 0 auto;
  column-gap: $sol-space-sm;
}

.menu {
  position: absolute;
  z-index: $sol-z-dropdown-menu;
  width: $menu-width;
  padding: $sol-space-sm 0;
  border-radius: 20px;
  background: $sol-color-white;
  font-weight: 400;
  text-align: left;
  filter: $sol-shadow-dropdown;
}

.inactive {
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  pointer-events: none;
}

.active:not(.flipped) {
  left: 0;
}

.flipped.active {
  right: 0;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  margin: $sol-space-xs 0;
  background: $sol-color-gray;
}
