@import "solar/Tokens.scss";

$input-size: 56px;
$button-size: 40px;

.component {
  display: flex;
  align-items: center;
}

.input {
  width: $input-size;
  height: $input-size;
  margin: 0 $sol-space-xs;
  padding: $sol-space-xs;
  border: solid 1px $sol-color-gray;
  border-radius: $sol-border-radius-sm;
  font-size: $sol-text-1;
  font-family: $sol-font-family;
  text-align: center;

  &:focus {
    box-shadow: 0px 0px 0px 2px $sol-color-black;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $button-size;
  height: $button-size;
  border: solid 1px $sol-color-gray;
  border-radius: $sol-border-radius-half;
  background: $sol-color-white;
  font-size: $button-size;

  &:focus {
    box-shadow: 0px 0px 0px 2px $sol-color-black;
  }
}
